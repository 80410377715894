<template>
  <div>
    <div class="admin-home" v-if="hiddenNav">
      <router-view />
    </div>
    <div class="admin-home admin-home-nav" v-else>
      <div>
        <left-menu
          :menus="items"
          :bottomEntries="bottomEntries"
          userType="seller"
        />
      </div>
      <div class="wrap">
        <topBar />
        <div class="content">
          <div class="page">
            <router-view />
          </div>
          <div class="footer">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="jsx">
import Footer from '~/components/Footer.vue'
import topBar from '~/components/topBar.vue'
import leftMenu from '~/components/LeftMenu.vue'
import { useRoute } from 'vue-router';
import { h, onBeforeMount, ref, watch, reactive } from 'vue'

const hiddenNav = ref(false)
const route = useRoute();

const items = reactive([
  {
    key: '/seller/dashboard',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-home' }),
      ]),
    routeName: 'dashboard',
    title: '',
    label: 'Dashboard',
  },
  {
    key: '/seller/brand/index',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-pinpai' }),
      ]),
    routeName: 'brand-index',
    title: '',
    label: 'Brands',
  },
  {
    key: '/seller/product',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-shangpin' }),
      ]),
    routeName: 'product',
    title: '',
    label: 'Products',
  },
  {
    key: '/seller/reports',
    icon:()=> h('svg', { class: 'icon', 'aria-hidden': 'true' }, 
    [ h('use', { 'xlink:href': '#icon-chart' })]),
    title: '',
    label: <div class="reports-beta">Reports <span>BETA</span></div>,
    children: [
      {
        routeName: 'reports-performance',
        key: '/seller/reports/performance',
        preKey: '/seller/reports',
        label: 'Performance',
        title: 'Performance',
      },
      {
        preKey: '/seller/reports',
        routeName: 'reports-product',
        key: '/seller/reports/product',
        label: 'Product',
        title: 'Product',
      },
      {
        preKey: '/seller/reports',
        routeName: 'reports-brand',
        key: '/seller/reports/brand',
        label: 'Brand',
        title: 'Brand',
      },
      {
        preKey: '/seller/reports',
        routeName: 'reports-publisher',
        key: '/seller/reports/publisher',
        label: 'Publisher',
        title: 'Publisher',
      },
    ]
  },
  {
    key: 'sub-settings',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-a-shezhi2' }),
      ]),
    routeName: '',
    title: '',
    label: 'Settings',
    children: [
      {
        key: '/seller/connection',
        preKey: 'sub-settings',
        routeName: 'connection',
        title: '',
        label: 'Connections',
      },
      {
        key: '/seller/account-info',
        preKey: 'sub-settings',
        routeName: 'account-info',
        title: '',
        label: 'Account Info',
      },
      {
        key: '/seller/commission-rule',
        preKey: 'sub-settings',
        routeName: 'commission-rule',
        title: '',
        label: 'Commission Rule',
      },
    ],
  },
  {
    key: '/seller/finance',
    icon:()=> h('svg', { class: 'icon', 'aria-hidden': 'true' }, 
    [ h('use', { 'xlink:href': '#icon-yinhangka' })]),
    title: '',
    label: <div class="reports-beta">Finance <span>BETA</span></div>,
    children: [
      {
        routeName: 'finance-payments',
        key: '/seller/finance/payments',
        preKey: '/seller/finance',
        label: 'Payments',
        title: 'Payments',
      },
    ]
  },
])
const bottomEntries = [
  {
    key: '/seller/referral',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-zuanshi' }),
      ]),
    routeName: 'referral',
    title: 'Refer Your Friends',
    label: 'Refer Your Friends',
    path: '/seller/referral',
  },
  {
    key: 'contact-us',
    icon: () =>
      h('svg', { class: 'icon icon-contact-us', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-youjian-cu' }),
      ]),
    label: 'Contact Us',
  },
  {
    key: '/help',
    icon: () =>
      h('svg', { class: 'icon icon-help', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-wenhao' }),
      ]),
    routeName: 'help',
    label: 'Need Help?',
  },
]
onBeforeMount(() => {
  const { meta } = route
  hiddenNav.value = meta.hiddenNav
})
</script>

<style lang="less" scoped>
.admin-home {
  display: flex;
  width: 100dvw;
  height: 100dvh;
  overflow-x: scroll;
  overflow-y: scroll;
}
.admin-home-nav {
  .wrap {
    flex: 1;
    display: flex;
    min-width: 1176px;
    flex-direction: column;
    .content {
      flex: 1;
      overflow: auto;
      .page {
        min-height: calc(100% - 72px);
      }
    }
  }
}
</style>
